import Text from '@components/atoms/Text';
import styled from '@emotion/styled';

const Button = styled.button`
  position: relative;
  padding: 14px 16px;

  span {
    transition: color 0.15s ease-in;
  }

  width: 90px;
  margin-right: 8px;

  @media only screen and (max-width: 768px) {
    margin-right: 0;
  }
`;

type PauseButtonProps = {
  isPaused: boolean;
  onClick: () => void;
};

const PauseButton = ({ isPaused, onClick }: PauseButtonProps) => {
  return (
    <Button onClick={onClick}>
      <Text as="span" variant="label" color="zest3">
        {isPaused ? 'PLAY' : 'PAUSE'}
      </Text>
    </Button>
  );
};

export default PauseButton;
