import Text from '@components/atoms/Text';
import styled from '@emotion/styled';

const Button = styled.button<{ $active: boolean }>`
  position: relative;
  padding: 14px 16px;

  margin-right: 8px;

  span {
    transition: color 0.15s ease-in;
    color: ${props => props.$active && 'var(--teal)'};
  }

  @media only screen and (max-width: 768px) {
    margin-right: 0;
  }
`;

type FullscreenButtonProps = {
  isFullscreen: boolean;
  setIsFullscreen: (isFullscreend: boolean) => void;
};

const FullscreenButton = ({
  isFullscreen,
  setIsFullscreen
}: FullscreenButtonProps) => {
  return (
    <Button
      $active={isFullscreen}
      onClick={() => {
        setIsFullscreen(!isFullscreen);
      }}
    >
      <Text as="span" variant="label" color="zest3">
        FULLSCREEN
      </Text>
    </Button>
  );
};

export default FullscreenButton;
