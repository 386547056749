import Button from '@components/Button';
import styled from '@emotion/styled';

const Styledbutton = styled(Button)`
  position: relative;
  z-index: 1;
`;

type PlayButtonProps = {
  onClick: () => void;
};

const PlayButton = ({ onClick }: PlayButtonProps) => {
  return (
    <Styledbutton onClick={onClick} variant="smallSecondary" icon="video">
      Play
    </Styledbutton>
  );
};

export default PlayButton;
