import styled from '@emotion/styled';
import { motion, MotionValue } from 'framer-motion';
import { useRef } from 'react';

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  width: 100%;
  height: 4px;

  padding: 1rem 0;
  margin: -1rem 0;

  @media only screen and (max-width: 768px) {
    display: none;
  }

  &:focus {
    outline: none;
  }
  cursor: pointer;
`;

const ProgressBar = styled(motion.div)`
  position: absolute;
  top: '14px';
  left: 0;
  border-radius: 4px;
  height: 4px;
  background-color: var(--teal);
  pointer-events: none;
`;

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 4px;
  cursor: pointer;
  border-radius: 12px;
  background-color: var(--grey5);
`;

export type ProgressProps = {
  progressContainerRef: (el: HTMLDivElement) => void;
  currentTime: number;
  duration: number;
  onProgressChange: (progress: number) => void;
  progressBarWidth: MotionValue<number>;
};

const Progress = ({
  progressContainerRef,
  currentTime,
  duration,
  onProgressChange,
  progressBarWidth
}: ProgressProps) => {
  const progressRef = useRef<HTMLDivElement>(null);
  const progress = currentTime / duration || 0;

  return (
    <Container
      ref={progressContainerRef}
      role="progressbar"
      tabIndex={0}
      aria-valuenow={progress}
      aria-valuemin={0}
      aria-valuemax={1}
      onMouseDown={event => onProgressChange(event.clientX)}
    >
      <Background />
      <ProgressBar ref={progressRef} style={{ width: progressBarWidth }} />
    </Container>
  );
};

export default Progress;
