import Image from '@components/website/Image';
import { BREAKPOINT_TABLET } from '@constants';
import styled from '@emotion/styled';
import { clamp } from '@helpers/animations';
import { iphone } from '@helpers/devices';
import useBounds from '@hooks/useBounds';
import { MediaVideoType } from '@types';
import { useInView, useMotionValue } from 'framer-motion';
import dynamic from 'next/dynamic';
import { Ref, useCallback, useEffect, useRef, useState } from 'react';
import ReactPlayer, { ReactPlayerProps } from 'react-player';
import { useMedia } from 'react-use';

import Navigation from './components/navigation';
import PlayButton from './components/playButton';

const PlayerLazy = dynamic(() => import('./components/Player'), {
  ssr: false
});

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: var(--black);
`;

const CoverWrapper = styled.div`
  position: absolute;
  inset: 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const CoverImage = styled(Image)`
  position: absolute;
  inset: 0;
`;

interface VideoPlayerProps {
  children?: React.ReactNode;
  video: MediaVideoType;
  coverSizes?: string;
  canPlay?: boolean;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({
  video,
  coverSizes,
  children,
  canPlay = true
}) => {
  const { src, cover } = video;
  const [isDragging, setIsDragging] = useState(false);
  const [isPaused, setIsPaused] = useState(true);
  const [isMuted, setIsMuted] = useState(false);
  const [userClickedPlay, setUserClickedPlay] = useState(false);
  const controlsTimeout = useRef<ReturnType<typeof setTimeout>>();
  const [isControlsHidden, setControlsHidden] = useState(true);
  const useNativeControls = iphone();

  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const progress = currentTime / duration || 0;
  const progressBarWidth = useMotionValue(0);

  const [progressContainerRef, progressContainerBounds] = useBounds();

  const containerRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<ReactPlayerProps>(null);

  const isNarrow = useMedia(`(max-width: ${BREAKPOINT_TABLET}px)`, false);
  const isInView = useInView(containerRef);
  const isPlaying = isInView && !isPaused && canPlay;

  useEffect(() => {
    progressBarWidth.set(progressContainerBounds.width * progress);
  }, [progressBarWidth, progress, progressContainerBounds]);

  const getTimeFromPosition = (x: number) => {
    const barWidth = progressContainerBounds.width;
    const prog = x / barWidth;
    return prog * duration;
  };

  const onProgressChange = (clientX: number) => {
    const { width, left } = progressContainerBounds;
    const x = clamp(clientX - left, 0, width);

    const time = getTimeFromPosition(x);

    progressBarWidth.set(x);

    videoRef.current?.seekTo(time);

    setCurrentTime(time);
  };

  const onVideoEnded = () => {
    setIsPaused(true);
    progressBarWidth.set(progressContainerBounds.width);
    setCurrentTime(duration);
  };

  const showControls = useCallback(() => {
    if (!userClickedPlay) return;

    const timeout = isNarrow ? 2500 : 1500;

    clearTimeout(controlsTimeout.current);
    setControlsHidden(false);

    controlsTimeout.current = setTimeout(() => {
      setControlsHidden(true);
    }, timeout);
  }, [isNarrow, userClickedPlay]);

  const hideControls = useCallback(() => {
    clearTimeout(controlsTimeout.current);
    setControlsHidden(true);
  }, []);

  return (
    <Container
      onMouseEnter={showControls}
      onMouseMove={showControls}
      onMouseLeave={hideControls}
      ref={containerRef}
    >
      <PlayerLazy
        playerRef={videoRef as Ref<ReactPlayer>}
        isPlaying={isPlaying}
        isDragging={isDragging}
        isMuted={isMuted}
        src={src}
        onProgress={({
          played,
          playedSeconds
        }: {
          played: number;
          playedSeconds: number;
        }) => {
          if (isPaused) return;

          progressBarWidth.set(played * progressContainerBounds.width);
          setCurrentTime(playedSeconds);
        }}
        onDuration={(total: number) => {
          setDuration(total);
        }}
        onEnded={onVideoEnded}
      />

      {children}

      {!useNativeControls && (
        <Navigation
          isPaused={isPaused}
          isControlsHidden={isControlsHidden}
          progressBarWidth={progressBarWidth}
          setIsMuted={setIsMuted}
          setIsDragging={setIsDragging}
          containerRef={containerRef}
          isMuted={isMuted}
          setIsPaused={setIsPaused}
          onProgressChange={onProgressChange}
          currentTime={currentTime}
          duration={duration}
          progressContainerRef={progressContainerRef}
        />
      )}

      {!userClickedPlay && (
        <CoverWrapper>
          {cover && (
            <CoverImage src={cover} objectFit="cover" sizes={coverSizes} />
          )}

          <PlayButton
            onClick={() => {
              setUserClickedPlay(true);
              setIsPaused(false);
              showControls();
            }}
          />
        </CoverWrapper>
      )}
    </Container>
  );
};

export default VideoPlayer;
