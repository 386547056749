import Text from '@components/atoms/Text';
import { VIDEO_CONTROLS_ANIMATION } from '@constants';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { RefObject, useEffect, useState } from 'react';
import { useEvent } from 'react-use';
import screenfull from 'screenfull';

import FullscreenButton from './fullscreenButton';
import MuteButton from './muteButton';
import PauseButton from './pauseButton';
import Progress, { ProgressProps } from './progress';

const Wrapper = styled(motion.div)`
  position: absolute;
  bottom: 26px;
  right: 4rem;
  left: 4rem;
  width: calc(100% - 8rem);

  display: flex;
  justify-content: space-between;

  background-color: var(--navy);
  border-radius: 4px;
  user-select: none;

  @media only screen and (max-width: 768px) {
    width: calc(100% - 2rem);
    left: 1rem;
    right: 1rem;
  }
`;

const Indicator = styled.div`
  position: relative;
  padding: 14px 16px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin-right: 12px;

  @media only screen and (max-width: 768px) {
    width: auto;
    margin-right: 0;
  }
`;

const TimeText = styled(Text)`
  width: 64px;
  color: var(--zest3);
`;

const DurationText = styled(TimeText)`
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

function padTime(value: number) {
  return ('0' + value).slice(-2);
}

const formatTime = (time: number) => {
  time = ~~time;
  const minutes = ~~(time / 60);
  const seconds = time % 60;

  const formattedTime = padTime(minutes) + ':' + padTime(seconds);

  return formattedTime;
};

type NavigationProps = {
  isPaused: boolean;
  setIsPaused: (value: boolean) => void;
  isMuted: boolean;
  setIsMuted: (value: boolean) => void;
  isControlsHidden: boolean;
  setIsDragging: (value: boolean) => void;
  containerRef: RefObject<HTMLDivElement>;
} & ProgressProps;

const Navigation = ({
  isPaused,
  setIsDragging,
  setIsPaused,
  duration,
  isMuted,
  setIsMuted,
  onProgressChange,
  containerRef,
  currentTime,
  progressBarWidth,
  isControlsHidden,
  progressContainerRef
}: NavigationProps) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  useEvent('fullscreenchange', () => setIsFullscreen(screenfull.isFullscreen));
  const [isScreenfullEnabled, setIsScreenfullEnabled] = useState(false);

  useEffect(() => {
    setIsScreenfullEnabled(screenfull.isEnabled);
  }, []);

  const handleSetNewPosition = (ev: MouseEvent) => {
    onProgressChange(ev.clientX);
  };

  const changeProgress = (progress: number) => {
    setIsDragging(true);
    onProgressChange(progress);

    document.addEventListener('mousemove', handleSetNewPosition);

    document.addEventListener(
      'mouseup',
      () => {
        setIsDragging(false);
        document.removeEventListener('mousemove', handleSetNewPosition);
      },
      { once: true }
    );
  };

  const handleChangeFullscreen = () => {
    if (screenfull.isEnabled) {
      if (screenfull.isFullscreen) {
        screenfull.exit();
      } else {
        screenfull.request(containerRef.current ?? undefined);
      }
    }
  };

  return (
    <Wrapper
      variants={VIDEO_CONTROLS_ANIMATION}
      initial="visible"
      animate={!isControlsHidden ? 'visible' : 'hidden'}
      style={{ pointerEvents: isControlsHidden ? 'none' : 'all' }}
    >
      <PauseButton isPaused={isPaused} onClick={() => setIsPaused(!isPaused)} />

      <Indicator>
        <TimeText as="span" variant="label" color="zest3">
          {formatTime(currentTime)}
        </TimeText>
        <Progress
          progressContainerRef={progressContainerRef}
          currentTime={currentTime}
          duration={duration}
          onProgressChange={changeProgress}
          progressBarWidth={progressBarWidth}
        />
        <DurationText as="span" variant="label" color="zest3">
          {formatTime(duration)}
        </DurationText>
      </Indicator>

      <MuteButton isMuted={isMuted} setIsMuted={setIsMuted} />

      {isScreenfullEnabled && (
        <FullscreenButton
          isFullscreen={isFullscreen}
          setIsFullscreen={handleChangeFullscreen}
        />
      )}
    </Wrapper>
  );
};

export default Navigation;
