import BackgroundVideo from '@components/BackgroundVideo';
import VideoPlayer from '@components/VideoPlayer';
import { BREAKPOINT_TABLET } from '@constants';
import { ResponsiveVideoType } from '@types';
import { useMedia } from 'react-use';

const ResponsiveVideo: React.FC<{
  src: ResponsiveVideoType | null;
  coverSizes: string;
  autoPlay?: boolean;
  canPlay?: boolean;
  variant?: 'backgroundVideo' | 'videoPlayer';
}> = ({ src, variant = 'backgroundVideo', coverSizes, ...props }) => {
  const isNarrow = useMedia(`(max-width: ${BREAKPOINT_TABLET}px)`, false);
  const video = isNarrow ? src?.mobile ?? src?.desktop : src?.desktop;

  if (!video) return null;

  return variant === 'backgroundVideo' ? (
    <BackgroundVideo videoMedia={video} coverSizes={coverSizes} {...props} />
  ) : (
    <VideoPlayer coverSizes={coverSizes} video={video} {...props} />
  );
};

export default ResponsiveVideo;
