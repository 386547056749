import { useRef, useState } from 'react';

import useWindowResize from './useWindowResize';

const useBounds = (): [
  (el: HTMLDivElement) => void,
  { width: number; height: number; top: number; left: number }
] => {
  const ref = useRef<HTMLDivElement | null>(null);
  const setRef = (el: HTMLDivElement) => (ref.current = el);
  const [bounds, setBounds] = useState({
    width: 0,
    height: 0,
    top: 0,
    left: 0
  });

  useWindowResize(() => {
    if (!ref.current) return;

    const width = ref.current.offsetWidth;
    const height = ref.current.offsetHeight;
    const { top, left } = ref.current.getBoundingClientRect();

    setBounds({ width, height, top, left });
  });

  return [setRef, bounds];
};

export default useBounds;
